<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Manage FAQs</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ FAQs
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn @click="manageCategory()" text x-large> FAQ category </v-btn>
                  <v-btn
                    v-if="checkIsAccessible('faq', 'create')"
                    @click="addFaq()"
                    class="mt-1 btn btn-primary"
                    style="color: #fff"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp; Add
                    FAQ
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div
              class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start"
            >
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    label="Title"
                    type="text"
                    dense
                    v-on:keyup.enter="getFaqs"
                    outlined
                    v-model="search.title"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                    label="Status"
                    outlined
                    dense
                    :items="active_statuses"
                    item-text="title"
                    clearable
                    item-value="value"
                    v-model="search.active"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="4" class="text-right">
                  <v-btn
                    @click.prevent="getFaqs"
                    v-on:keyup.enter="getFaqs"
                    class="btn btn-primary w-35"
                    style="color: #fff"
                    :loading="loading"
                  >
                    <v-icon small outlined>fas fa-search</v-icon>&nbsp; Search
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <div class="">
              <v-skeleton-loader v-if="loading" type="table-thead"> </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table">
                <thead>
                  <tr class="px-3">
                    <th class="px-3 wrap-column">Title</th>
                    <th class="px-3 wrap-column">Category</th>
                    <th class="px-3 wrap-column">Status</th>
                    <th class="px-3 text-center">Action</th>
                  </tr>
                </thead>

                <draggable
                  @change="sort"
                  v-model="faqs"
                  class="text-left"
                  tag="tbody"
                  handle=".handle"
                >
                  <tr v-for="(faq, index) of faqs" :key="faq.id">
                    <td class="px-3 wrap-column">
                      <i class="cursor-drag fas fa-sort handle pr-2"></i>
                      <a href="#" @click="editFaq(faq.id)" class="mr-2">
                        {{ faq.title }}</a
                      >
                    </td>
                    <td class="px-3 wrap-column">
                      {{ faq.parent ? faq.parent.title : "N/A" }}
                    </td>
                    <td class="px-3 pt-3 wrap-column">
                      <span
                        class="badge text-lg`"
                        v-bind:class="{
                          'badge-success': faq.is_active,
                          'badge-danger': !faq.is_active,
                        }"
                      >
                        {{ faq.is_active ? "Active" : "Inactive" }}
                      </span>
                    </td>

                    <td class="px-2 text-center">
                      <b-dropdown
                        size="sm"
                        variant="link"
                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                        no-caret
                        right
                        no-flip
                      >
                        <template v-slot:button-content>
                          <slot>
                            <span>
                              <i class="flaticon-more-1"></i>
                            </span>
                          </slot>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover">
                          <!--                                                  <b-dropdown-text tag="div" class="navi-item">-->
                          <!--                                                    <a href="#" class="navi-link" @click="detail(faq.id)">-->
                          <!--                                                            <span class="navi-icon">-->
                          <!--                                                              <i class="fas fa-eye"></i>-->
                          <!--                                                            </span>-->
                          <!--                                                      <span class="navi-text"> View </span>-->
                          <!--                                                    </a>-->
                          <!--                                                  </b-dropdown-text>-->
                          <b-dropdown-text
                            tag="div"
                            class="navi-item"
                            v-if="checkIsAccessible('faq', 'edit')"
                          >
                            <a href="#" class="navi-link" @click="editFaq(faq.id)">
                              <span class="navi-icon">
                                <i class="flaticon-edit"></i>
                              </span>
                              <span class="navi-text"> Edit </span>
                            </a>
                          </b-dropdown-text>

                          <b-dropdown-text
                            tag="div"
                            class="navi-item"
                            v-if="checkIsAccessible('faq', 'delete')"
                          >
                            <a href="#" class="navi-link" @click="deleteFaq(faq.id)">
                              <span class="navi-icon">
                                <i class="fas fa-trash"></i>
                              </span>
                              <span class="navi-text"> Delete</span>
                            </a>
                          </b-dropdown-text>
                        </div>
                        <!--end::Navigation-->
                      </b-dropdown>
                    </td>
                  </tr>
                </draggable>

                <template v-if="faqs.length == 0">
                  <tr>
                    <td class="text-center px-3" colspan="6">
                      <strong>
                        No data available to display.</strong>
                    </td>
                  </tr>
                </template>
              </table>
              <b-pagination
                v-show="faqs.length > 0"
                @input="getFaqs"
                class="pull-right mt-7"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
              ></b-pagination>
            </div>
            <div
              class="wizard wizard-2"
              id="kt_wizard_v2"
              data-wizard-state="step-first"
              data-wizard-clickable="true"
            >
              <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
                <!--begin: Wizard Form-->

                <div class="row"></div>
                <div class="row">
                  <div class="col-12" v-if="total > perPage">
                    <!--  -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <create-and-update ref="faq" @refresh_faq="getFaqs"></create-and-update>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CreateAndUpdate from "./CreateAndUpdate";

import draggable from "vuedraggable";
import FaqService from "@/core/services/faq/FaqService";

const faqService = new FaqService();
export default {
  name: "faq",
  display: "Table",
  order: 8,
  components: {
    CreateAndUpdate,

    draggable,
  },
  data() {
    return {
      active_statuses: [
        { title: "Active", value: "1" },
        { title: "Inactive", value: "0" },
      ],
      faqs: [],
      page: null,
      perPage: null,
      total: null,
      search: {
        active: "1",
        is_parent: "null",
      },
      dragging: false,
      loading: false,
    };
  },
  mounted() {
    this.getFaqs();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Faq", route: "wizard-1" },
      { title: "Faq" },
    ]);
  },
  methods: {
    getFaqs() {
      this.loading = true;
      faqService
        .paginate(this.search, this.page)
        .then((response) => {
          this.faqs = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
          this.loading = false;
        })
        .catch((error) => {
          this.faqs = [];
          this.loading = false;
          // console.log(error);
        });
    },
    addFaq() {
      this.$refs["faq"].showModal();
    },
    editFaq(id) {
      this.$refs["faq"].showModal(id);
    },
    manageCategory() {
      this.$router.push({
        name: "faq-category",
      });
    },

    deleteFaq(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            faqService
              .delete(id)
              .then((response) => {
                this.getFaqs();
              })
              .catch((error) => {
                //console.log(error);
              });
          }
        },
      });
    },
    sort() {
      faqService
        .sort(this.faqs)
        .then((res) => {
          this.$snotify.success("Sorted");
          this.getFaqs();
        })
        .catch((err) => {
          this.$snotify.success("Sorted Error");
        });
    },
    detail(id) {
      this.$router.push({ name: "faq-photo", params: { id: id } });
    },
  },
};
</script>
<style scoped>
.buttons {
  margin-top: 35px;
}

.truncate {
  max-width: 500px;
  max-height: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
